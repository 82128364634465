import axios from "axios";

const fetchGames = async () => {
  try {
    const { data } = await axios.get(
      process.env.REACT_APP_FETCH_GAMES_URL, {
        headers: { 'x-api-key': process.env.REACT_APP_X_API_KEY }
      })
    console.log({games: data.games})
    return data.games
  } catch (error) {
    console.error(error)
  }
}

export const fetchGamesMap = async () => {
  const games = await fetchGames()
  return games.reduce((acc, game) => {
    acc[game.id] = game;
    return acc;
  }, {})
}

export default fetchGames
