import { LogoContainer } from '../logo-container/logo-container.component';
import { MenuContainer } from '../menu-container/menu-container.component';
import './header-container.styles.scss'

export const HeaderContainer = () => {
  return (
    <div className="header-container">
      <MenuContainer />
    </div>
  )
}
