import { useSelector } from 'react-redux';
import { selectGames, selectGamesLoading } from '../../store/games/games.selector';
import { Game } from '../../types/game.type';
import { GameItem } from '../game-item/game-item.component';
import { SectionBorder } from '../section-border/section-border.component';
import Spinner from '../spinner/spinner.component';
import './games-section.styles.scss'

const mapAvailability = (availability: string): number => {
  switch(availability) {
    case 'available': return 0
    case 'new': return 1
    case 'coming': return 2
    case 'off-limits': return 3
    default: return 100
  }
}

export const GamesSection = () => {
  const games = useSelector(selectGames) as Game[]
  const sortedGames = games.sort((g1, g2) => mapAvailability(g1.availability) - mapAvailability(g2.availability))
  const isGamesLoading = useSelector(selectGamesLoading)
  return (
    <div className="section-games" id="section-games">
    <SectionBorder text="You found an easter egg! Yay!"/>
    <div className="games-container">
        <div className="games-header">The Mystery Board</div>
        <div className="games-sub-header">The world is your escape room.</div>
        <div className="games-note">*Prices are in AUD (Australian dollars)</div>
        {

          isGamesLoading ?
            <Spinner /> :
            <div className="games-body">{
              sortedGames.map((game) => 
                (
                  game.availability === 'off-limits' ? '' :
                  <GameItem key={game.id} game={game} />
                )
              )
            }
            </div>
        }
        </div>
    </div>
  )
}
