import { SectionBorder } from '../section-border/section-border.component'
import './game-details.styles.scss'
import { Fragment, useState } from 'react'
import { loadStripe } from "@stripe/stripe-js";
import { FormInput } from '../../form-input/form-input.component'
import { Game } from '../../types/game.type'

interface GameDetailsProps {
  game: Game
}

let stripePromise: any

const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string)
  }
  return stripePromise
}

export const GameDetails = ({ game }: GameDetailsProps) => {
  const [stripeError, setStripeError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [customerEmail, setCustomerEmail] = useState('')

  const difficultyClass = game.difficulty < 4 ? 'diff-easy' : (game.difficulty < 5 ? 'diff-medium' : 'diff-hard')

  const updateCustomerEmail = (event: any) => {
    const { value } = event.target
    setCustomerEmail(value)
  }

  // https://www.youtube.com/watch?v=BSN2KcB_h-M
  const redirectToCheckout = async (event: any) => {
    event.preventDefault()

    const checkoutOptions = {
      lineItems: [{
        price: game.priceKey,
        quantity: 1
      }],
      mode: "payment",
      successUrl: `${window.location.origin}/success?email=${customerEmail}&gameid=${game.id}`,
      cancelUrl: `${window.location.origin}/game/${game.id}`,
      customerEmail,
    };

    setLoading(true);

    const stripe = await getStripe();
    await stripe.redirectToCheckout(checkoutOptions);

    setLoading(false);
  };

  const isGameAvailable = ['new','available'].includes(game.availability)

  return (
    <Fragment>
      <SectionBorder text="For the header!!!"/>
      <div className="game-background"></div>
      <div className="game-main-container">
        <div className="game-details-container">
          <div className="game-details-card" style={{backgroundImage: `url(${game.imageUrl})`}}></div>
          <div className="game-details-description-container">
            <div className="game-details-description">
              <div className="game-details-header">
                <div className="game-details-title">{game.title}</div>
                <div className="game-details-flag-icon">
                  <span className={`game-details-flag-icon fi fi-${game.location}`}></span>
                  
                </div>
              </div>
              
              <div className="game-details-description">{game.fullDescription}</div>
              <div className="game-sub-details">
                <div className="game-details-price">${game.price}&nbsp;</div>
                <div className="game-details-duration">| Hours to Days</div>
              </div>
              <div className="game-details-difficulty">
                  {
                    [...Array(5-game.difficulty)].map((_, index) => (
                      <span key={`diff-${index}`} className='game-difficulty-icon-off material-symbols-outlined'>explore</span>
                    ))
                  }
                  {
                    [...Array(game.difficulty)].map((_, index) => (
                      <span key={`diff-${index}`} className={`game-difficulty-icon material-symbols-outlined ${difficultyClass}`}>explore</span>
                    ))
                  }
              </div>
              
            </div>
            {
              isGameAvailable ? 
                <div className='customer-email-form'>
                  <form onSubmit={redirectToCheckout}>
                    <FormInput label='Email:' type='email' placeholetype='email' required value={customerEmail} onChange={updateCustomerEmail}/>
                    <div className="submit-button-container">
                      <button className='btn btn-accept' type='submit' disabled={isLoading}>
                        Purchase Mystery
                      </button>
                    </div>
                  </form>
                </div>
                : <div className="game-not-available">Not yet accepting applicants.</div>
            }

          </div>
        </div>
      </div>
    </Fragment>
  )
}