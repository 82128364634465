import { HeaderContainer } from "../../components/header-container/header-container.component"
import { Outlet } from 'react-router-dom'
import { Fragment } from "react"
import { FooterContainer } from "../../components/footer-container/footer-container.component"

export const Navigation = () => {

  return (
    <Fragment>
      <HeaderContainer />
      <Outlet />
      <FooterContainer />
    </Fragment>
  )
}