import { Button } from '../button/button.component';
import './home-section.styles.scss'

export const HomeSection = () => {

  return (
    <div className="section-home" id="section-home">
      <div className="home-text-container">
        <div className="company-name">The GeoMystery Club</div>
        <div className="company-motto">We seek. We solve.</div>
      </div>
      <div className="btn-explore-container">
        <Button href="#section-games" classNames={['btn-explore']} text="seek mystery"/>
      </div>
    </div>
  )
}
