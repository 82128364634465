import './main-container.styles.scss'
import { HomeSection } from '../home-section/home-section.component';
import { GamesSection } from '../games-section/games-section.component';
import { AboutSection } from '../about-section/about-section.component';
import { FaqSection } from '../faq-section/faq-section.component';
import { ContactSection } from '../contact-section/contact-section.component';
import { ReviewsSection } from '../reviews-section/reviews-section.component';

export const MainContainer = () => {
  return (
    <div className="main-container">
      <HomeSection />
      <GamesSection />
      <AboutSection />
      <FaqSection />
      <ReviewsSection />
      <ContactSection />
    </div>
  )
}
