import { SectionBorder } from "../../components/section-border/section-border.component";
import { SuccessContainer } from "../../components/success-container/success-container.component";
import './success.styles.scss'
export const Success = () => {

  return (
    <div className="success">
      <SuccessContainer />
    </div>
  )
};

