import { useState } from 'react'
import './faq-item.styles.scss'
import parse from 'html-react-parser'

type FaqItemProps = {
  question: string,
  answer: string
}

export const FaqItem = ({ question, answer }: FaqItemProps) => {
  const[showAnswer, toggleShowAnswer] = useState(false)

  const onToggleShowAnswer = () => {
    toggleShowAnswer(!showAnswer)
  }

  return (
    <div className="faq-item-container">
      <div className={`faq-question ${showAnswer ? 'faq-question-selected' : ''}`} onClick={onToggleShowAnswer}>
        {question}
      </div>
      {
        showAnswer &&
        <div className="faq-answer">
          {parse(answer)}
        </div>
      }
    </div>
  )
}