import { createSelector  } from "reselect";

const selectGamesReducer = (state) => state.games

export const selectGames = createSelector(
  [selectGamesReducer],
  (gamesSlice) => gamesSlice.games
)

export const selectGamesMap = createSelector(
  [selectGames],
  (games) => games.reduce((acc, game) => {
    acc[game.id] = game;
    return acc;
  }, {})
)

export const selectGamesLoading = createSelector(
  [selectGamesReducer],
  (gamesSlice) => gamesSlice.isLoading
)