import './App.scss';
import { Routes, Route } from 'react-router-dom';
import { Home } from './routes/home/home.component';
import { Game } from './routes/game/game.component';
import { Navigation } from './routes/navigation/navigation.component';
import { fetchGamesStart } from './store/games/games.action';
import { useDispatch } from 'react-redux';
import "@stripe/stripe-js";
import { Success } from './routes/success/success.component';

function App() {

  const dispatch = useDispatch()
  dispatch(fetchGamesStart())
  
  return (
    <Routes>
      <Route path="/" element={<Navigation />}>
        <Route index element={<Home />} />
        <Route path='game/*' element={<Game />}></Route>
        <Route path='success' element={<Success />}></Route>
      </Route>
    </Routes>
  )
}

export default App;
