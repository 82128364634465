import { GameContainer } from "../../components/game-container/game-container.component";
import {Routes,Route} from 'react-router-dom'

export const Game = () => {

  return (
    <Routes>
      <Route path=':id' element={<GameContainer />}></Route>
    </Routes>
  )
};