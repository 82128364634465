import './game-item.styles.scss'
import { Game } from '../../types/game.type';
import { Link } from 'react-router-dom';

interface GameItemProps {
    game: Game
}

export const GameItem = ({ game }: GameItemProps) => {
  const availabilityText = game.availability === 'new' ? 'new mystery' : 'foreshadowing mystery'
  return (
    <Link to={`game/${game.id}`} className="game-item" key={game.id} style={{backgroundImage: `url(${game.imageUrl})`}}>
      <div className="game-item-topper">
        <div className="game-item-header">
          <div className="game-item-flag-icon">
            <span className={`game-item-flag-icon fi fi-${game.location}`}></span>
          </div>
          <div className="game-item-header-content">
            <div className="game-title">{game.title}</div>
            <div className="game-price">${game.price}</div>
          </div>
        </div>
        {
          game.availability === 'available' ? '' :
          <div className={`game-availability`}>
            <span className={`game-availability-text game-availability-${game.availability}`}>{availabilityText}</span>
          </div>
        }

      </div>
      <div className="game-difficulty">
        {
          [...Array(5-game.difficulty)].map((_, index) => (
            <span key={`diff-${index}`} className="game-difficulty-icon-off material-symbols-outlined">explore</span>
          ))
        }
        {
          [...Array(game.difficulty)].map((_, index) => (
            <span key={`diff-${index}`} className="game-difficulty-icon material-symbols-outlined">explore</span>
          ))
        }
      </div>
    </Link>
  )
}