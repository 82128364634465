import './button.styles.scss'

interface ButtonProps {
    classNames: string[],
    href: string,
    text: string
  }

export const Button = ({ href, classNames, text }: ButtonProps) => {

  return (
      <a href={href} className={['btn', ...classNames].join(' ')}>{text}</a>
  )
}
