import { SectionBorder } from '../section-border/section-border.component'
import './contact-section.styles.scss'

export const ContactSection = () => {
  return (
    <div className="section-contact" id="section-contact">
      <SectionBorder text="Aw, that's so sweet!"/>
      <div className="contact-container">
        <div className="contact-header">Contact Us</div>
        <div className="contact-item">
          <img src="/assets/logo.png" alt="emaillogo" className='social-media-logo' />
          <div className="contact-value">geomaster@thegeomysteryclub.com.au</div>
        </div>
        <div className="contact-item">
          <img src="/assets/fb.svg" alt="fblogo" className='social-media-logo' />
          <div className="contact-value"><a href="https://www.facebook.com/thegeomysteryclub" target="_blank">thegeomysteryclub</a></div>
        </div>
        <div className="contact-item">
          <img src="/assets/ig.svg" alt="iglogo" className='social-media-logo' />
          <div className="contact-value"><a href="https://www.instagram.com/thegeomysteryclub" target="_blank">@thegeomysteryclub</a></div>
        </div>
        {/* <div className="contact-item">
          <img src="/assets/twitter.svg" alt="twitterlogo" className='social-media-logo' />
          <div className="contact-value"><a href="https://twitter.com/geomysteryclub" target="_blank">@geomysteryclub</a></div>
        </div> */}
      </div>
    </div>
  )
}
