import { useState } from 'react'
import { FaqItem } from '../faq-item/faq-item.component'
import { SectionBorder } from '../section-border/section-border.component'
import './faq-section.styles.scss'

export const FaqSection = () => {
  const faqItems = [{
    question: 'What is the gameplay? Is it like online escape rooms?',
    answer: 
      '&nbsp;&nbsp;&nbsp;Though hugely inspired by online escape rooms, the games offered here are less confined in rooms. Having influenced by geocaching mystery caches, the general gameplay is based on real world exploration using available tools from <span className="bold">Google</span>: <span className="bold">Google Maps</span>, <span className="bold">Google Street View</span> and of course, <span className="bold">Google Search</span>. <br/><br/> &nbsp;&nbsp;&nbsp;Experience the thrill and joy of discovery while virtually walking along the streets of various cities or maybe while researching about the history of famous landmarks across the globe. Puzzles will mostly include exploration of certain locations and solving word or picture riddles.'
    },{
      question: ' Is there a time limit?',
      answer: 
        '&nbsp;&nbsp;&nbsp;<span className="bold">NO</span>. The games can span from hours to days depending on your team\'s pace. Most teams play a few chapters at a time, whenever the puzzle-solving crew is avaible.'
    },{
      question: 'Do I need to play with a team?',
      answer: 
        '&nbsp;&nbsp;&nbsp;<span className="bold">NO</span>. You can do it on your own, at your own time, and at your own pace. You can share it with friends but the game does sync the progress by game code, so that you can go back to your game any time and all players with the same code get synced, too.'
    },{
      question: 'How does it work if played by a team?',
      answer: 
        '&nbsp;&nbsp;&nbsp;The games are <span className="bold">not hosted games</span> so the games can be played, stopped, or resumed at <span className="bold">any time</span> by <span className="bold">anyone</span> with the link (hopefully, just your friends). Whenever anybody solves a chapter, the game is synced with the server, but not automatically with other players. The other players need to refresh their page to sync their game with the latest save state. In addition, there is no in-game chat, audio, or video feature so you could use whatever collaboration tool you like.'
    },{
      question: 'How do I determine a game\'s difficulty?',
      answer: 
        '&nbsp;&nbsp;&nbsp;The number of <span className="bold">compass icons</span> in the game cards indicate difficulty with 5 being the most difficult. The club strongly believes that difficulty can be very subjective and players\' skills and experience surely vary, so take the difficulty indicator with a grain of salt. The game mechanics might get a bit confusing at the start, but once you get the hang of it, you\'ll hopefully enjoy the challenging journey.'
    },{
      question: 'Do I get hints? Or am I on my own?',
      answer: 
        '&nbsp;&nbsp;&nbsp;<span className="bold">YES</span>, you will have access to hints in-game. Each game should have some form of a help button somewhere. Have fun looking for it *wink wink emoji*. If you wish to get more hints or need any form of external assistance, you can contact the <span className="bold">geomaster</span> via <span className="bold">geomaster@thegeomysteryclub.com.au</span>.'
    },{
      question: 'What I find an issue e.g. outdated maps or clues since Google Maps updates regularly?',
      answer: 
        '&nbsp;&nbsp;&nbsp;<span className="bold">Months and years</span> are usually provided so that you could adjust the street view given the month and or year. It is acknowledged that this could be a problem, so the <span className="bold">geomaster</span> will try to regularly check the validity of the clues. In the event that there is a legitimate issue, please do drop an email to <span className="bold">geomaster@thegeomysteryclub.com.au</span> with the details. It should be only fair that you get refunded and given a chance to re-play for free once the game has been updated.'
    },{
      question: 'Can I provide feedback and/or suggestions?',
      answer: 
        '&nbsp;&nbsp;&nbsp;<span className="bold">YES YES YES</span>. The club would love to hear from GeoMysts! Please do so via an email to <span className="bold">geomaster@thegeomysteryclub.com.au</span>. Please be nice, because <span className="bold">The GeoMystery Club</span> is an organisation of peace, wit, and love. *wink wink emoji*'
    },{
      question: 'Do I need a specific type of computer?',
      answer: 
        '&nbsp;&nbsp;&nbsp;<span className="bold">YES</span>. The games are best played using desktop / laptop computer and Google Chrome. You should be able to view images and text using smart phones or tablets, but there might be issues in html rendering and hovering.'
    },{
      question: 'How safe are my card and personal data?',
      answer: 
        '&nbsp;&nbsp;&nbsp;Payments are managed by <span className="bold">Stripe</span> and the club does not store payment details outside stripe except for the payment ID used to link the purchase and the generated game code. The club also does NOT collect or store personal data other than the email used to purchase the game for the sole purpose of correspondence.'
    },{
      question: 'How long after the purchase will it take before I can start my mission?',
      answer: 
        '&nbsp;&nbsp;&nbsp;After a purchase, you should receive an email of your receipt via <span className="bold">Stripe</span>, then shortly after (<span className="bold">seconds to minutes</span>),you should be able to receive an email from the geomaster with a link to your mission. If it takes longer, please drop a message to <span className="bold">geomaster@thegeomysteryclub.com.au</span>.'
    },{
      question: 'What kind of support do I get in general?',
      answer: 
        '&nbsp;&nbsp;&nbsp;Currently, the <span className="bold">geomaster</span> is still working a 9-5 shift in Sydney so support might be <span className="bold">not fully 24/7</span>. However, lost emails / game codes, game sync issues, refund reuqests (as of now, only for progress not beyond chapter 3 under reasonable circumstance), should be easy and quick to resolve but still manually handled (requiring the <span className="bold">geomaster</span> to access his laptop and do the magic).'
    }
  ]

  return (
    <div className="section-faq" id="section-faq">
      <SectionBorder text="More like potential questions!"/>
      <div className="faq-container">
        <div className="faq-header">Frequently Asked Questions</div>
        {
          faqItems.map((faqItem, index) => (
            <FaqItem {...faqItem} key={`faq-${index}`} />
          ))
        }
       </div>
    </div>
  )
}