import './game-container.styles.scss'
import { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { selectGamesLoading, selectGamesMap } from '../../store/games/games.selector'
import { loadStripe } from "@stripe/stripe-js";
import Spinner from '../spinner/spinner.component'
import { GameDetails } from '../game-details/game-details.component'

type GameContainerParams = {
  id: string
}

export const GameContainer = () => {
  const { id } = useParams<GameContainerParams>()
  const gameId = Number(id)
  let gamesMap = useSelector(selectGamesMap)
  const isGamesLoading = useSelector(selectGamesLoading)
  const game = gamesMap[gameId]

  return (
    <Fragment>
      {
        isGamesLoading ?
          <Spinner /> :
          <div className="game-container" style={{backgroundImage: `url(${game.imageUrl})`}}>
            <GameDetails game={game} />
          </div>
      }
    </Fragment>
  )
}