import { Component } from 'react';
import { SectionBorder } from '../section-border/section-border.component';
import './about-section.styles.scss'

export class AboutSection extends Component {

  render() {
    return (
      <div className="section-about" id="section-about">
        <SectionBorder text="Oh, hidden text!"/>
        <div className="about-us-container">
          <div className="about-us-text-container">
            <div className="about-us-header">The GeoMystery Club</div>
            <div className="about-us-text">
              <span className="main-high">The GeoMystery Club</span> is an <span className="main-high">online mystery experience </span>
              provider inspired by the geomaster's love for 2 things: 
              &nbsp;<span className="sub-high">geocaching</span> and <span className="sub-high">escape rooms</span>. 
              &nbsp;<span className="main-high">Geocaching</span> is a modern day treasure hunt wherein
              a cache (the <span className="sub-high">treasure</span>) is hidden anywhere in the world, 
              then clues are provided to whomever (the <span className="sub-high">seeker</span>)
              wishes to find the cache. Caches that require seekers to <span className="sub-high">solve a puzzle first</span> are called
              &nbsp;<span className="main-high">Mystery Caches</span>, hence the motto: <span className="main-high">"We seek. We solve."</span>
              <br/><br/>
              Having a passion for <span className="sub-high">creating puzzles</span> and <span className="sub-high">walking around</span>,
              the geomaster eventually created a geocache series called <span className="main-high">The GeoMystery Club</span>, catering
              to geocachers who enjoy hunting mystery caches and solving related puzzles. Because of rules
              and limitations imposed by the geocaching community, the geomaster decided to try creating his
              own experiences (this time with his own rules) online and learn some web design and programming along the way.
            </div>
          </div>
        </div>
      </div>
    )
  }
}