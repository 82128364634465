import './success-container.styles.scss'
import { useSearchParams, Navigate } from 'react-router-dom'
import { useSelector } from "react-redux";
import { Fragment} from "react";
import { selectGamesLoading, selectGamesMap } from "../../store/games/games.selector";
import { SuccessDetails } from "../success-details/success-details.component";
import Spinner from '../spinner/spinner.component';

type SuccessContainerParams = {
  email: string
}

export const SuccessContainer = () => {
  const [searchParams] = useSearchParams()
  const email = searchParams.get('email')
  const id = searchParams.get('gameid')
  const gameId = Number(id)
  const gamesMap = useSelector(selectGamesMap)
  const isGamesLoading = useSelector(selectGamesLoading)
  const game = gamesMap[gameId]

  return email && (game || isGamesLoading) ? 
    (
      <Fragment>
      {

        isGamesLoading ?
          <Spinner /> :
          <div className="success-container" style={{backgroundImage: `url(${game.imageUrl})`}}>
            <SuccessDetails game={game} email= {email} />
          </div>
      }
      </Fragment>
    ) :
    <Navigate to='/' replace/>
};
