
import { createAction } from "../../utils/reducer.utils";
import { GAMES_ACTION_TYPES } from "./games.types";

export const fetchGamesStart = () => {
  return createAction(GAMES_ACTION_TYPES.FETCH_GAMES_START)
}

export const fetchGamesSuccess = (games) => {
  return createAction(GAMES_ACTION_TYPES.FETCH_GAMES_SUCCESS, games)
}

export const fetchGamesFailed = (error) => {
  return createAction(GAMES_ACTION_TYPES.FETCH_GAMES_FAIL, error)
}

