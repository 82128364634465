import './spinner.styles.scss'

const Spinner = () => {
  return (
    <div className="spinnerOverlay">
      <div className="spinnerContainer" />
    </div>
  )
}

export default Spinner