import { GAMES_ACTION_TYPES } from "./games.types"

export const GAMES_INITIAL_STATE = {
  games: [],
  isLoading: false,
  error: null
}

export const gamesReducer = (state = GAMES_INITIAL_STATE, action = {}) => {
  const { type, payload } = action
  switch (type) {
    case GAMES_ACTION_TYPES.FETCH_GAMES_START:
      return {
        ...state,
        isLoading: true
      }
    case GAMES_ACTION_TYPES.FETCH_GAMES_SUCCESS:
      return {
        ...state,
        games: payload,
        isLoading: false
      }
    case GAMES_ACTION_TYPES.FETCH_GAMES_FALSE:
      return {
        ...state,
        error: payload,
        isLoading: false
      }
    default:
      return state
  }
} 