import { Button } from '../button/button.component'
import { SectionBorder } from '../section-border/section-border.component'
import './reviews-section.styles.scss'

export const ReviewsSection = () => {

  const reviewItems = [
    {
      game: 'Trail As Old As Time',
      reviewer: 'EscapeTheRoomers',
      link: 'https://www.escapetheroomers.com/post/the-geomystery-club-trail-as-old-as-time',
      comment: 'Overall, we found the cryptic poems to be written well with the subtle clues that would result in some fun eureka moments. The interface was a true highlight with how easy it was to navigate and explore. Any armchair adventurer or geocaching lover would happily enjoy this game. If you cannot wander the streets of Sydney in person to solve a mystery, then this game is the next best thing.'
    }
  ]

  return (
    <div className="section-reviews" id="section-reviews">
      <SectionBorder text="Think positive"/>
      <div className="reviews-container">
        <div className="reviews-header">Reviews</div>
        {
          reviewItems.map((reviewItem, index) => (
            <div className='review-item-container' key={`review-${index}`} >
              <div className="review-game">{reviewItem.game}</div>
              <div className="review-comment">{reviewItem.comment}</div>
              <div className="review-reviewer">- {reviewItem.reviewer}</div>
              <div className="review-link">
                  <a href={reviewItem.link} className='review-link-btn' target="_blank">Full review</a>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}