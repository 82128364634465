import { SectionBorder } from "../section-border/section-border.component";
import './success-container.styles.scss'
import { Fragment } from "react";
import { Game } from "../../types/game.type";

interface SuccessDetailsProps {
  game: Game,
  email: string
}

export const SuccessDetails = ({ game, email }: SuccessDetailsProps) => {
  return (
    <Fragment>
      <SectionBorder text="For the header!!!"/>
      <div className="success-main-container">
        <div className="success-message-container">
          <div className="game-card" style={{backgroundImage: `url(${game.imageUrl})`}}></div>
          <div className="content">
            <div className="header">
              <div className="title">{game.title}</div>
              <div className="flag-icon-container">
                <span className={`flag-icon fi fi-${game.location}`}></span>
              </div>
            </div>
            <div className="welcome">
              Welcome to <span className="highlight">The GeoMystery Club: {game.title}</span> investigation team! 
              From here on out, we will be calling you an <span className="highlight">Honorary Geomyst</span>.
              <br/><br/>The <span className="highlight">GeoMaster Detective</span> has sent instructions to your email: <span className="highlight">{email}</span>.
              <br/><br/>Mystery presents to the curious and brave.<br/><span className="highlight">Seek and solve.</span>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
};
