import './menu-item.styles.scss'
import { HashLink as Link } from 'react-router-hash-link'

type MenuItemProps = {
  name: string,
  section: string
  link?: string
}

export const MenuItem = ({ name, section, link }: MenuItemProps) => {
  return (
    <div className="menu-item">
      {
        link ?
        <a href={link}>
          {name}
        </a> :
        <Link to={`/#section-${section}`}>
          {name}
        </Link> 
      }
    </div>
  )
}
