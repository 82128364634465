import { takeLatest, all, call, put } from 'redux-saga/effects'
import { fetchGamesFailed, fetchGamesSuccess } from './games.action'
import { GAMES_ACTION_TYPES } from './games.types'
import fetchGames from './games.util'

export function* fetchGamesAsync() {
  try {
    const games = yield call(fetchGames)
    yield put(fetchGamesSuccess(games))
  } catch(error) {
    yield put(fetchGamesFailed(error))
  }
}

export function* onFetchGames() {
  yield takeLatest(GAMES_ACTION_TYPES.FETCH_GAMES_START, fetchGamesAsync)
}

export function* gamesSaga() {
  yield all([
    call(onFetchGames)
  ])
}