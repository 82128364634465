import { MenuItem } from '../menu-item/menu-item.component'
import './menu-container.styles.scss'

export const MenuContainer = () => {
  const menuItems = [
    { name: 'home', section: 'home' }, 
    { name: 'mysteries', section: 'games' },
    { name: 'about', section: 'about' },
    { name: 'faq', section: 'faq' },
    { name: 'reviews', section: 'reviews' },
    { name: 'contact', section: 'contact' },
    //{ name: 'shop', section: 'shop', link: 'https://shop.thegeomysteryclub.com.au' },
  ]
    
  return (
    <div className='menu-container'>
      {
        menuItems.map((menuItem) => 
          (
            <MenuItem {...menuItem} key={menuItem.section} />
          )
        )
      }
    </div>
  )
}
